<template>
  <span
    class="hm-tag"
    :class="[
      `-${category}`,
      {
        '-disabled': disabled,
        '-clickable': clickable,
      },
    ]"
    :disabled="disabled"
    @click="onClick"
  >
    <slot />
  </span>
</template>

<script setup lang="ts">
import { ValueOf } from '@/utils/types/types'

type Props = {
  disabled?: boolean
  category?: ValueOf<typeof TAG_TYPE>
  clickable?: boolean
}

type Emits = {
  (emit: 'click'): void
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  category: TAG_TYPE.PRIMARY,
})

const emits = defineEmits<Emits>()

const onClick = () => {
  if (props.disabled) return false
  return emits('click')
}
</script>
<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use 'sass:color';
@use '@/assets/styles/mixins' as m;

.hm-tag {
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  padding: v.space(1) v.space(2);
  transition: color 0.2s ease, background-color 0.2s ease;
  width: fit-content;

  &.-clickable {
    cursor: pointer;
  }

  &.-primary {
    background-color: v.$primary-color;
    color: v.$white;
    font-weight: 600;

    &.-clickable:hover {
      background-color: v.$yellow;
      box-shadow: 0 0 6px v.$yellow;
    }

    &.-disabled {
      background-color: v.$button-disabled-color;
      color: v.$gray-3;
    }
  }

  &.-limited {
    background-color: v.$pink-1;
    color: v.$white;

    &.-clickable:hover {
      background-color: color.adjust(
        v.$pink-1,
        $saturation: 10%,
        $lightness: 10%
      );
      box-shadow: 0 0 6px v.$pink-1;
    }

    &.-disabled {
      background-color: v.$button-disabled-color;
      color: v.$gray-3;
    }
  }

  &.-pink {
    background-color: v.$pink;
    color: v.$white;

    &.-clickable:hover {
      background-color: color.adjust(
        v.$pink,
        $saturation: 10%,
        $lightness: 10%
      );
      box-shadow: 0 0 6px v.$pink;
    }

    &.-disabled {
      background-color: v.$button-disabled-color;
      color: v.$gray-3;
    }
  }

  &.-outline {
    background-color: transparent;
    border: v.$pink 1px solid;
    color: v.$pink;

    &.-clickable:hover {
      background-color: v.$pink;
      color: v.$white;
    }

    &.-disabled {
      background-color: v.$button-disabled-color;
      color: v.$gray-3;
    }
  }

  &.-gray {
    background-color: v.$gray-5;
    color: v.$white;

    &.-clickable:hover {
      background-color: color.adjust(
        v.$gray-5,
        $saturation: 10%,
        $lightness: 10%
      );
    }

    &.-disabled {
      background-color: v.$button-disabled-color;
      color: v.$gray-3;
    }
  }

  &.-danger {
    background-color: v.$red;
    color: v.$white;

    &.-clickable:hover {
      background-color: color.adjust(v.$red, $saturation: 10%, $lightness: 10%);
    }

    &.-disabled {
      background-color: v.$button-disabled-color;
      color: v.$gray-3;
    }
  }

  &.-blue {
    background-color: v.$green-2;
    color: v.$white;

    &.-clickable:hover {
      background-color: color.adjust(
        v.$green-2,
        $saturation: 10%,
        $lightness: 10%
      );
    }

    &.-disabled {
      background-color: v.$button-disabled-color;
      color: v.$gray-3;
    }
  }

  @include m.sp {
    border-radius: 2px;
    font-size: 10px;
  }
}
</style>
